<template>
  <div id="productionSearch" class="productionSearch">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="left">
        <div class="filterItem">
          <div style="min-width:56px;margin-right:10px">货号</div>
          <Input
            placeholder="请输入搜索内容"
            style="width: 210px"
            v-model.trim="listData.productArticleNumber"
            @keyup.enter.native="getTableDataList"
          />
        </div>
        <div class="filterItem">
          <div style="min-width:26px;margin-right:10px">品号</div>
          <Input
            placeholder="请输入搜索内容"
            style="width: 210px"
            v-model.trim="listData.productNumber"
            @keyup.enter.native="getTableDataList"
          />
        </div>
        <!-- <div class="filterItem">
          <div style="min-width:26px;margin-right:10px">花型</div>
          <Input
            placeholder="请输入搜索内容"
            style="width: 210px"
            v-model.trim="listData.designType"
            @keyup.enter.native="getTableDataList"
          />
        </div> -->
      </div>
    </div>
    <!-- 按钮 -->
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="refresh">
            <i class="iconfont iconicon-shuaxin"></i>
            刷新
          </div>
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu"></i>
            同步
          </div>
          <!-- <div class="item" @click="showTableTitleDialog">
            <i class="iconfont iconicon-chuansuokuang"></i>
            表格设置
          </div> -->
          <div class="item"></div>
          <div class="item" @click="exportProduction">
            <i class="iconfont iconicon-daochu"></i>
            导出
          </div>
          <div class="item" @click="getTableDataList">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <Table
      :columns="tableColumns"
      :data="tableData"
      border
      show-summary
      :summary-method="handleSummary"
      :loading="loading"
    ></Table>
    <!-- 分页 -->
    <Page
      class-name="page"
      :total="listData.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      tableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "品号",
          key: "productNumber",
          minWidth: 150
        },
        {
          title: "货号",
          key: "articleNumber",
          minWidth: 150
        },
        {
          title: "匹数合计",
          key: "clothQuantity",
          minWidth: 150
        },
        {
          title: "重量(KG)合计",
          key: "totalNum",
          render: (h, params) => {
            return h("span", params.row.totalNum.toFixed(2));
          },
          width: 200
        }
      ],
      tableData: [],
      listData: {
        pageSize: 10,
        pageNum: 1,
        productArticleNumber: "",
        productNumber: "",
        designType: "",
        total: 0
      },
      clothQuantityTotal: "",
      totalNumTotal: ""
    };
  },
  created() {
    this.getTableDataList();
  },
  methods: {
    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.getTableDataList();
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.getTableDataList();
    },
    showTableTitleDialog() {},
    // 刷新表格
    refresh() {
      this.loading = true;
      this.getTableDataList();
    },
    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableDataList();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取列表数据
    getTableDataList() {
      this.axios({
        url: "/dtsum/zongtong/product/productController/productionSheetSearch",
        method: "get",
        params: this.listData
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.tableData = res.data.body.productionEntrySheetList.list;
            this.listData.total = res.data.body.productionEntrySheetList.total;
            this.listData.pageSize =
              res.data.body.productionEntrySheetList.pageSize;
            this.listData.pageNum =
              res.data.body.productionEntrySheetList.pageNum;
            this.totalNumTotal = res.data.body.totalNumTotal.toFixed(2);
            this.clothQuantityTotal = res.data.body.clothQuantityTotal;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    exportProduction() {
      window.location.href = `${this.baseUrl}/dtsum/zongtong/product/productController/exportProductionSheet?productionCargoNum=${this.listData.productArticleNumber}&productNumber=${this.listData.productNumber}&designType=${this.listData.designType}`;
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "clothQuantity") {
          sums[key] = {
            key,
            value: this.clothQuantityTotal
          };
        } else if (key === "totalNum") {
          sums[key] = {
            key,
            value: this.totalNumTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss" scoped>
#productionSearch {
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: $--fontsize-small;
    .filterItem {
      display: inline-flex;
      line-height: 32px;
      margin-right: 50px;
      margin-bottom: 20px;
      i {
        margin-left: 9px;
      }
    }
  }
  .userDefined {
    font-size: $--fontsize-small;
    background: #f5f5f5;
    padding: 10px 0 0 0;
    border-radius: $--radius-standard;
    position: relative;
    display: flex;
    .triangle {
      position: absolute;
      top: -10px;
      right: 90px;
      background: #fff;
      font-size: 0;
      line-height: 0;
      width: 0;
      height: 0;
      border-color: transparent transparent #f5f5f5 transparent;
      border-style: solid;
      border-width: 0px 5px 10px 5px;
    }
    .left {
      width: calc(100% - 150px);
      .filterItem {
        display: inline-flex;
        margin: 0 50px 10px 0;
        div {
          line-height: 32px;
        }
      }
    }
  }
  .box {
    font-size: $--fontsize-small;
    .boxItem {
      margin: 10px 0;
    }
  }
  .button {
    font-size: $--fontsize-small;
    margin-bottom: 20px;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
        .item {
          display: inline-block;
          margin-right: 30px;
          color: $--color-blue-standard;
          cursor: pointer;
          &:nth-child(2) {
            margin-right: 20px;
          }
          &:nth-child(3) {
            width: 1px;
            height: 14px;
            background: #dcdada;
            margin-right: 20px;
            vertical-align: middle;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton {
          width: 130px;
          height: 32px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    font-size: $--fontsize-small;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
